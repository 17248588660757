// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Avatar_Avatar__196lW {\n    border-radius: 50%;\n    margin: 0px 8px 0px 8px;\n    object-fit: cover;\n  }", "",{"version":3,"sources":["webpack://src/styles/Avatar.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,uBAAuB;IACvB,iBAAiB;EACnB","sourcesContent":[".Avatar {\n    border-radius: 50%;\n    margin: 0px 8px 0px 8px;\n    object-fit: cover;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Avatar": "Avatar_Avatar__196lW"
};
export default ___CSS_LOADER_EXPORT___;
