// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NavBar_NavBar__1amC6 {\n  background-color: #ffffff;\n  border: 1px solid #dadadf;\n  color: black;\n}\n\n.NavBar_NavLink__34ons {\npadding: 2px;\nfont-size: 12px;\n}\n\ni {\n  color: rgb(41, 180, 180);\n}\n\n.NavBar_DropLink__1YijW {\ncolor: black;\n}\n\n.NavBar_Active__3PBZb i {\ncolor: #2142b2;\n}\n\n.NavBar_Active__3PBZb {\ncolor: #2142b2;\n}\n\nTitle {\ncolor: #000;\n}\n\ni {\nfont-size: .5em !important;\n}\n", "",{"version":3,"sources":["webpack://src/styles/NavBar.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,yBAAyB;EACzB,YAAY;AACd;;AAEA;AACA,YAAY;AACZ,eAAe;AACf;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;AACA,YAAY;AACZ;;AAEA;AACA,cAAc;AACd;;AAEA;AACA,cAAc;AACd;;AAEA;AACA,WAAW;AACX;;AAEA;AACA,0BAA0B;AAC1B","sourcesContent":[".NavBar {\n  background-color: #ffffff;\n  border: 1px solid #dadadf;\n  color: black;\n}\n\n.NavLink {\npadding: 2px;\nfont-size: 12px;\n}\n\ni {\n  color: rgb(41, 180, 180);\n}\n\n.DropLink {\ncolor: black;\n}\n\n.Active i {\ncolor: #2142b2;\n}\n\n.Active {\ncolor: #2142b2;\n}\n\nTitle {\ncolor: #000;\n}\n\ni {\nfont-size: .5em !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"NavBar": "NavBar_NavBar__1amC6",
	"NavLink": "NavBar_NavLink__34ons",
	"DropLink": "NavBar_DropLink__1YijW",
	"Active": "NavBar_Active__3PBZb"
};
export default ___CSS_LOADER_EXPORT___;
