export const useRedirectSign = () => {
  // const history = useHistory()
  // useEffect(() => {
  //   const handleMount = async () => {
  //     try {
  //       const data = await axios.post("/dj-rest-auth/token/refresh/")
  //       console.log(data)
  //       console.log("RedirectSign")
  //       history.push("/")
  //     } catch (err) {
  //       console.log("Redirected no refresh token")
  //     }
  //   }
  //   handleMount()
  // }, [history])
}

export default useRedirectSign
