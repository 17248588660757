// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CommentCreate_Form__3IviQ {\n    border: none;\n    border-bottom: 2px #00d1ff solid;\n  }\n  \n  .CommentCreate_Button__1SiU5 {\n    border: none;\n    background-color: #242a3d;\n    color: aliceblue;\n    border-radius: 100px;\n    padding: 4px 10px;\n    min-width: 75px;\n    margin: 5px;\n  }\n  \n  .CommentCreate_Button__1SiU5:hover {\n    cursor: pointer;\n    background-color: aliceblue;\n    color: #242a3d;\n  }", "",{"version":3,"sources":["webpack://src/styles/CommentCreate.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,gCAAgC;EAClC;;EAEA;IACE,YAAY;IACZ,yBAAyB;IACzB,gBAAgB;IAChB,oBAAoB;IACpB,iBAAiB;IACjB,eAAe;IACf,WAAW;EACb;;EAEA;IACE,eAAe;IACf,2BAA2B;IAC3B,cAAc;EAChB","sourcesContent":[".Form {\n    border: none;\n    border-bottom: 2px #00d1ff solid;\n  }\n  \n  .Button {\n    border: none;\n    background-color: #242a3d;\n    color: aliceblue;\n    border-radius: 100px;\n    padding: 4px 10px;\n    min-width: 75px;\n    margin: 5px;\n  }\n  \n  .Button:hover {\n    cursor: pointer;\n    background-color: aliceblue;\n    color: #242a3d;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Form": "CommentCreate_Form__3IviQ",
	"Button": "CommentCreate_Button__1SiU5"
};
export default ___CSS_LOADER_EXPORT___;
