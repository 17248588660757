// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Dropdown_DropdownItem__3lfs3 {\n    display: inline-block;\n    max-width: 50%;\n    padding: 0px;\n  }\n\n.Dropdown_DropdownItem__3lfs3 i {\n  color: #000;\n}\n  \n  .Dropdown_Absolute__nVpHp {\n    position: absolute;\n    right: 0;\n    z-index: 99;\n  }", "",{"version":3,"sources":["webpack://src/styles/Dropdown.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,cAAc;IACd,YAAY;EACd;;AAEF;EACE,WAAW;AACb;;EAEE;IACE,kBAAkB;IAClB,QAAQ;IACR,WAAW;EACb","sourcesContent":[".DropdownItem {\n    display: inline-block;\n    max-width: 50%;\n    padding: 0px;\n  }\n\n.DropdownItem i {\n  color: #000;\n}\n  \n  .Absolute {\n    position: absolute;\n    right: 0;\n    z-index: 99;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DropdownItem": "Dropdown_DropdownItem__3lfs3",
	"Absolute": "Dropdown_Absolute__nVpHp"
};
export default ___CSS_LOADER_EXPORT___;
