// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Comment_Owner__3sgYe {\n    font-weight: bold;\n    color: #2142b2;\n    margin-right: 8px;\n  }\n  \n  .Comment_Date__10qt0 {\n    font-weight: lighter;\n    color: #c1c1c5;\n  }", "",{"version":3,"sources":["webpack://src/styles/Comment.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,cAAc;IACd,iBAAiB;EACnB;;EAEA;IACE,oBAAoB;IACpB,cAAc;EAChB","sourcesContent":[".Owner {\n    font-weight: bold;\n    color: #2142b2;\n    margin-right: 8px;\n  }\n  \n  .Date {\n    font-weight: lighter;\n    color: #c1c1c5;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Owner": "Comment_Owner__3sgYe",
	"Date": "Comment_Date__10qt0"
};
export default ___CSS_LOADER_EXPORT___;
