/* Context file used to fetch the current user
 * Interceptors used to keep a user loged in
 * Fix needed - affected by the Gmail mobile browser issue
 * A fix is needed for the Gmail mobile browser issue as it is
   affecting the axiosReq interceptor in mobile
 * The Gmail mobile browser issue seems to be all requests 
   getting 401 so the token refresh is being rejected */
import { createContext, useContext, useEffect, useState } from "react";
import { axiosInstance } from "../api/axiosDefaults";
// import axios from "axios"
// import { axiosReq, axiosRes } from "../api/axiosDefaults"
// import { useHistory } from "react-router-dom"
// import { removeTokenTimestamp, shouldRefreshToken } from "../utils/utils"

export const CurrentUserContext = createContext();
export const SetCurrentUserContext = createContext();

export const useCurrentUser = () => useContext(CurrentUserContext);
export const useSetCurrentUser = () => useContext(SetCurrentUserContext);

export const CurrentUserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  // const history = useHistory()

  // const handleMount = async () => {
  //   try {
  //     const { data } = await axiosRes.get("dj-rest-auth/user/")
  //     setCurrentUser(data)
  //   } catch (err) {
  //     console.log(err)
  //   }
  // }

  const getCurrentUserData = async () => {
    if (localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user"));
      console.log(user);
      const { data } = await axiosInstance.get(`/profiles/${user?.pk}/`);
      console.log(data);
      setCurrentUser({
        ...user,
        profile_image: data?.data?.image,
        remaining_projects: data?.data?.remaining_projects,
      });
    }
  };

  useEffect(() => {
    getCurrentUserData();
  }, []);

  // useMemo(() => {
  //   axiosReq.interceptors.request.use(
  //     async (config) => {
  //       if (shouldRefreshToken()) {
  //         try {
  //           await axios.post("/dj-rest-auth/token/refresh/")
  //         } catch (err) {
  //           setCurrentUser((prevCurrentUser) => {
  //             if (prevCurrentUser) {
  //               history.push("/signin")
  //             }
  //             console.log(shouldRefreshToken())
  //             return null
  //           })
  //           removeTokenTimestamp()
  //           return config
  //         }
  //       }
  //       return config
  //     },
  //     (err) => {
  //       return Promise.reject(err)
  //     }
  //   )

  //   axiosRes.interceptors.response.use(
  //     (response) => response,
  //     async (err) => {
  //       if (err.response?.status === 401) {
  //         try {
  //           await axios.post("/dj-rest-auth/token/refresh/")
  //         } catch (err) {
  //           setCurrentUser((prevCurrentUser) => {
  //             if (prevCurrentUser) {
  //               history.push("/signin")
  //             }
  //             return null
  //           })
  //           removeTokenTimestamp()
  //         }
  //         return axios(err.config)
  //       }
  //       return Promise.reject(err)
  //     }
  //   )
  // }, [history])

  return (
    <CurrentUserContext.Provider value={currentUser}>
      <SetCurrentUserContext.Provider value={setCurrentUser}>
        {children}
      </SetCurrentUserContext.Provider>
    </CurrentUserContext.Provider>
  );
};
