// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PageNotFound_Margin__7_Kyp {\n    margin-top: 10vh;\n}", "",{"version":3,"sources":["webpack://src/styles/PageNotFound.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB","sourcesContent":[".Margin {\n    margin-top: 10vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Margin": "PageNotFound_Margin__7_Kyp"
};
export default ___CSS_LOADER_EXPORT___;
